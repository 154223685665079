import {h, onMounted} from "vue"

import 'package-vue-component-ui/dist/package-vue-component-ui.css'
import './styles/app.css'

export const rootApp = (app, props) => ({
    setup() {
        onMounted(() => {
        })
    },
    render: () => h(app, props)
})

export const rootSSRApp = (App, props) => ({
    setup() {
        onMounted(() => {
        })
    },
    render: () => h(App, props, {default: () => props.$slots.default})
})
